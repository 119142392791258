// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/uprate-styles.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$(),
  _s2 = $RefreshSig$(),
  _s3 = $RefreshSig$(),
  _s4 = $RefreshSig$(),
  _s5 = $RefreshSig$(),
  _s6 = $RefreshSig$(),
  _s7 = $RefreshSig$(),
  _s8 = $RefreshSig$(),
  _s9 = $RefreshSig$(),
  _s10 = $RefreshSig$(),
  _s11 = $RefreshSig$(),
  _s12 = $RefreshSig$(),
  _s13 = $RefreshSig$(),
  _s14 = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/uprate-styles.tsx");
  import.meta.hot.lastModified = "1712947911928.2556";
}
// REMIX HMR END

import { Avatar, HStack, Heading, Text, useColorModeValue } from "@chakra-ui/react";
import { Link } from "@remix-run/react";
function UprateLogo() {
  return <Link to="/explore">
      <HStack spacing={2}>
        <Avatar src={"/assets/logo/logo-circle.png"} size="md" name="Uprate.AI" padding={1} />
        <UprateH5>Uprate.AI</UprateH5>
      </HStack>
    </Link>;
}
_c = UprateLogo;
function UprateH1({
  children,
  ...rest
}) {
  _s();
  return <Heading as="h1" size="4xl" textColor={useColorModeValue("gray.900", "white")} {...rest}>
      {children}
    </Heading>;
}
_s(UprateH1, "3skuTHwppfEdh6aKNlffDjyTB8U=", false, function () {
  return [useColorModeValue];
});
_c2 = UprateH1;
function UprateH2({
  children,
  ...rest
}) {
  _s2();
  return <Heading as="h2" size="3xl" textColor={useColorModeValue("gray.900", "white")} {...rest}>
      {children}
    </Heading>;
}
_s2(UprateH2, "3skuTHwppfEdh6aKNlffDjyTB8U=", false, function () {
  return [useColorModeValue];
});
_c3 = UprateH2;
function UprateH3({
  children,
  ...rest
}) {
  _s3();
  return <Heading as="h3" size="2xl" textColor={useColorModeValue("gray.800", "gray.200")} {...rest}>
      {children}
    </Heading>;
}
_s3(UprateH3, "3skuTHwppfEdh6aKNlffDjyTB8U=", false, function () {
  return [useColorModeValue];
});
_c4 = UprateH3;
function UprateH4({
  children,
  ...rest
}) {
  _s4();
  return <Heading as="h4" size="xl" textColor={useColorModeValue("gray.800", "gray.200")} {...rest}>
      {children}
    </Heading>;
}
_s4(UprateH4, "3skuTHwppfEdh6aKNlffDjyTB8U=", false, function () {
  return [useColorModeValue];
});
_c5 = UprateH4;
function UprateH5({
  children,
  ...rest
}) {
  _s5();
  return <Heading as="h5" size="lg" letterSpacing={"wide"} textColor={useColorModeValue("gray.700", "gray.300")} {...rest}>
      {children}
    </Heading>;
}
_s5(UprateH5, "3skuTHwppfEdh6aKNlffDjyTB8U=", false, function () {
  return [useColorModeValue];
});
_c6 = UprateH5;
function UprateH6({
  children,
  ...rest
}) {
  _s6();
  return <Heading as="h6" size="md" letterSpacing={"wide"} textColor={useColorModeValue("gray.700", "gray.300")} {...rest}>
      {children}
    </Heading>;
}
_s6(UprateH6, "3skuTHwppfEdh6aKNlffDjyTB8U=", false, function () {
  return [useColorModeValue];
});
_c7 = UprateH6;
function UprateH7({
  children,
  ...rest
}) {
  _s7();
  return <Heading as="h6" size="sm" letterSpacing={"wider"} textColor={useColorModeValue("gray.600", "gray.400")} {...rest}>
      {children}
    </Heading>;
}
_s7(UprateH7, "3skuTHwppfEdh6aKNlffDjyTB8U=", false, function () {
  return [useColorModeValue];
});
_c8 = UprateH7;
function UprateH8({
  children,
  ...rest
}) {
  _s8();
  return <Heading as="h6" size="xs" letterSpacing={"wider"} textColor={useColorModeValue("gray.400", "gray.600")} {...rest}>
      {children}
    </Heading>;
}
_s8(UprateH8, "3skuTHwppfEdh6aKNlffDjyTB8U=", false, function () {
  return [useColorModeValue];
});
_c9 = UprateH8;
function UprateB1({
  children,
  ...rest
}) {
  _s9();
  return <Text as="p" fontSize="2xl" textColor={useColorModeValue("gray.900", "white")} {...rest}>
      {children}
    </Text>;
}
_s9(UprateB1, "3skuTHwppfEdh6aKNlffDjyTB8U=", false, function () {
  return [useColorModeValue];
});
_c10 = UprateB1;
function UprateB2({
  children,
  ...rest
}) {
  _s10();
  return <Text as="p" fontSize="xl" textColor={useColorModeValue("gray.900", "white")} {...rest}>
      {children}
    </Text>;
}
_s10(UprateB2, "3skuTHwppfEdh6aKNlffDjyTB8U=", false, function () {
  return [useColorModeValue];
});
_c11 = UprateB2;
function UprateB3({
  children,
  ...rest
}) {
  _s11();
  return <Text as="p" fontSize="lg" letterSpacing={"wide"} textColor={useColorModeValue("gray.800", "gray.200")} {...rest}>
      {children}
    </Text>;
}
_s11(UprateB3, "3skuTHwppfEdh6aKNlffDjyTB8U=", false, function () {
  return [useColorModeValue];
});
_c12 = UprateB3;
function UprateB4({
  children,
  ...rest
}) {
  _s12();
  return <Text as="p" fontSize="md" letterSpacing={"wide"} textColor={useColorModeValue("gray.800", "gray.200")} {...rest}>
      {children}
    </Text>;
}
_s12(UprateB4, "3skuTHwppfEdh6aKNlffDjyTB8U=", false, function () {
  return [useColorModeValue];
});
_c13 = UprateB4;
function UprateB5({
  children,
  ...rest
}) {
  _s13();
  return <Text as="p" fontSize="sm" letterSpacing={"wider"} textColor={useColorModeValue("gray.600", "gray.400")} {...rest}>
      {children}
    </Text>;
}
_s13(UprateB5, "3skuTHwppfEdh6aKNlffDjyTB8U=", false, function () {
  return [useColorModeValue];
});
_c14 = UprateB5;
function UprateB6({
  children,
  ...rest
}) {
  _s14();
  return <Text as="p" fontSize="xs" letterSpacing={"wider"} textColor={useColorModeValue("gray.500", "gray.500")} {...rest}>
      {children}
    </Text>;
}
_s14(UprateB6, "3skuTHwppfEdh6aKNlffDjyTB8U=", false, function () {
  return [useColorModeValue];
});
_c15 = UprateB6;
export { UprateB1, UprateB2, UprateB3, UprateB4, UprateB5, UprateB6, UprateH1, UprateH2, UprateH3, UprateH4, UprateH5, UprateH6, UprateH7, UprateH8, UprateLogo };
var _c, _c2, _c3, _c4, _c5, _c6, _c7, _c8, _c9, _c10, _c11, _c12, _c13, _c14, _c15;
$RefreshReg$(_c, "UprateLogo");
$RefreshReg$(_c2, "UprateH1");
$RefreshReg$(_c3, "UprateH2");
$RefreshReg$(_c4, "UprateH3");
$RefreshReg$(_c5, "UprateH4");
$RefreshReg$(_c6, "UprateH5");
$RefreshReg$(_c7, "UprateH6");
$RefreshReg$(_c8, "UprateH7");
$RefreshReg$(_c9, "UprateH8");
$RefreshReg$(_c10, "UprateB1");
$RefreshReg$(_c11, "UprateB2");
$RefreshReg$(_c12, "UprateB3");
$RefreshReg$(_c13, "UprateB4");
$RefreshReg$(_c14, "UprateB5");
$RefreshReg$(_c15, "UprateB6");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;